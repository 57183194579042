<template>
  <div class="input-search" :class="shape">
    <search-icon
      class="search-icon"
      stroke-size="normal"
      :width="shape === 'round' ? '16' : '20'"
      :height="shape === 'round' ? '16' : '20'"
    ></search-icon>
    <input
      ref="searchInput"
      type="search"
      class="sub-text-2"
      :value="modelValue"
      :placeholder="placeholder"
      @input="actions.inputEvent($event)"
      @focus="actions.focusEvent()"
      @focusout="actions.focusoutEvent()"
      @keypress.enter="actions.submitEvent()"
    />
  </div>
</template>

<script>
import { reactive, ref } from "vue";
import SearchIcon from "../icons/SearchIcon";

export default {
  name: "InputSearch",
  components: { SearchIcon },
  props: {
    placeholder: {
      type: String,
      default: "검색어를 입력해주세요.",
    },
    modelValue: {
      type: String,
      default: "",
    },
    searchType: {
      type: String,
      default: "pressEnter",
      validator: (val) => {
        return ["pressEnter", "typingText"].includes(val);
      },
    },
    shape: {
      type: String,
      default: "underline",
      validator(value) {
        return ["underline", "round"].includes(value);
      },
    },
    underlineBorder: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "search"],
  setup(props, { emit }) {
    const searchInput = ref();

    const state = reactive({
      focusClass: "",
    });

    const actions = {
      focusEvent: () => {
        state.focusClass = props.underlineBorder
          ? `border-dark`
          : `border-primary`;
      },
      focusoutEvent: () => {
        state.focusClass = "";
      },
      inputEvent: (e) => {
        emit("update:modelValue", e.target.value);
        console.log(e.target.value);

        if (!e.target.value) {
          emit("search");
        }

        if (props.searchType === "typingText") {
          emit("search");
        }
      },
      submitEvent: () => {
        if (props.searchType === "pressEnter") {
          emit("search");
        }
      },
    };

    return { state, actions, searchInput };
  },
};
</script>

<style scoped>
.input-search {
  position: relative;
  width: 100%;
}

.input-search .search-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translate(0, -50%);
}

.input-search input {
  background: #ffffff;
  border-radius: 100px;
  padding: 11px 12px 11px 34px;
  width: 100%;
}

.input-search input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  color: #d0d5de;
}

.input-search.round input {
  border: none;
}

.input-search input:focus {
  border-color: #9ea0a6;
}

.input-search.underline input {
  border-radius: unset;
  border-bottom: 1px solid #e6eaef;
  border-left: none;
  border-top: none;
  border-right: none;
  padding: 0 0 13px 26px;
}

.input-search.underline .search-icon {
  left: 0;
  bottom: 15px;
  top: unset;
  transform: unset;
}
</style>
