<template>
  <div class="stat-card">
    <div class="sub-text-s2 text-gray-third">
      <community-icon fill-color="#D0D5DE"></community-icon>
      {{ title }}
    </div>
    <div class="count">
      <h2 class="text-gray-reverse">
        {{ count ? count : "-" }}
      </h2>
      <span v-if="report > 0" class="text-blue-50">
        <i class="fas fa-caret-up"></i>{{ report }}
      </span>
      <span v-if="report < 0" class="text-red-50">
        <i class="fas fa-caret-down"></i>{{ report * -1 }}
      </span>
    </div>
  </div>
</template>
<script>
import CommunityIcon from "@/components/console/icons/CommunityIcon.vue";

export default {
  name: "MemberStatsItem",
  components: { CommunityIcon },
  props: {
    count: {
      type: Number,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    report: {
      type: [Number, String],
      required: false,
    },
  },
};
</script>
<style src="./style.css" scoped></style>
