<template>
  <div>
    <card-basic class="member-card__mo">
      <avatar-with-name :user="connectedUser"></avatar-with-name>
      <ul class="info-list">
        <li>
          <span class="sub-text-s3 text-gray-second">멤버 유형</span>
          <div>
            <span
              v-for="(type, index) in connectedUser.type"
              :key="`type-${index}`"
              class="sub-title-s3"
            >
              {{ type }}<span class="comma">, </span>
            </span>
          </div>
        </li>
        <li>
          <span class="sub-text-s3 text-gray-second">총 결제금액</span>
          <span class="sub-title-s3">{{ state.sumPayments }}</span>
        </li>
        <li>
          <span class="sub-text-s3 text-gray-second">게시글 수</span>
          <span class="sub-title-s3">
            {{
              connectedUser.articlesCount ? connectedUser.articlesCount : "-"
            }}
          </span>
        </li>
        <li>
          <span class="sub-text-s3 text-gray-second">댓글 수</span>
          <span class="sub-title-s3">
            {{
              connectedUser.commentsCount ? connectedUser.commentsCount : "-"
            }}
          </span>
        </li>
        <li>
          <span class="sub-text-s3 text-gray-second">팔로우 시작일</span>
          <span class="sub-title-s3" v-html="state.createdAt"> </span>
        </li>
      </ul>
    </card-basic>

    <div class="member-card__pc">
      <div class="col1">
        <avatar-with-name :user="connectedUser"></avatar-with-name>
      </div>
      <div class="col2">
        <span
          v-for="(type, index) in connectedUser.type"
          :key="`type-${index}`"
          class="sub-text-s2"
        >
          {{ type }}
          <span v-if="index !== connectedUser.type.length - 1" class="comma">
            ,
          </span>
        </span>
      </div>
      <div class="col3">
        <span class="sub-text-s2">
          {{ state.sumPayments }}
        </span>
      </div>
      <div class="col4">
        <span
          class="sub-text-s2"
          :class="{ 'text-blue-50': !!connectedUser.articlesCount }"
        >
          {{ connectedUser.articlesCount ? connectedUser.articlesCount : "-" }}
        </span>
      </div>
      <div class="col5">
        <span class="sub-text-s2">
          {{ connectedUser.commentsCount ? connectedUser.commentsCount : "-" }}
        </span>
      </div>
      <div class="col6">
        <span class="sub-text-s2" v-html="state.createdAt"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import helper from "@/helper";
import CardBasic from "@/components/console/cards/CardBasic.vue";
import AvatarWithName from "@/components/console/avatars/AvatarWithName.vue";

export default {
  name: "MemberItem",
  components: { AvatarWithName, CardBasic },
  props: {
    connectedUser: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const state = reactive({
      createdAt: computed(() => {
        let connected = props.connectedUser.connected;
        if (connected) {
          if (connected["created_at"]) {
            return helper.dateTimeFormat(
              props.connectedUser.connected["created_at"],
              2
            );
          } else {
            return "-";
          }
        }
        return "-";
      }),
      sumPayments: computed(() => {
        let connectedUser = props.connectedUser;
        if (connectedUser.sumPayments) {
          if (!connectedUser.sumPaymentsUSD) {
            return `₩ ${helper.priceFormat(connectedUser.sumPaymentsKRW)}`;
          } else {
            return `₩ ${helper.priceFormat(connectedUser.sumPayments)}`;
          }
        }
        return "-";
      }),
    });

    const actions = {};

    return { state, actions, helper };
  },
};
</script>

<style src="./style.css" scoped></style>
