<template>
  <icon-base
    icon-name="filter-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.27331 4L7.18466 8.26693C7.38754 8.48824 7.50008 8.77757 7.50008 9.0778V12.382L8.50008 11.882V9.0778C8.50008 8.77757 8.61262 8.48824 8.81549 8.26693L12.7268 4H3.27331ZM2.81867 3C2.12258 3 1.75858 3.82746 2.22894 4.34058L6.44751 8.94265C6.48132 8.97954 6.50008 9.02776 6.50008 9.0778V12.7056C6.50008 13.3003 7.12593 13.6871 7.65785 13.4211L9.05785 12.7211C9.32888 12.5856 9.50008 12.3086 9.50008 12.0056V9.0778C9.50008 9.02776 9.51884 8.97954 9.55265 8.94265L13.7712 4.34058C14.2416 3.82746 13.8776 3 13.1815 3H2.81867Z"
        :fill="fillColor"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>

    <template v-if="fill">
      <path
        d="M2.22894 4.34058C1.75858 3.82746 2.12258 3 2.81867 3H13.1815C13.8776 3 14.2416 3.82746 13.7712 4.34058L9.55265 8.94265C9.51884 8.97954 9.50008 9.02776 9.50008 9.0778V12.0056C9.50008 12.3086 9.32888 12.5856 9.05785 12.7211L7.65785 13.4211C7.12593 13.6871 6.50008 13.3003 6.50008 12.7056V9.0778C6.50008 9.02776 6.48132 8.97954 6.44751 8.94265L2.22894 4.34058Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";
export default {
  name: "FilterIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
