<template>
  <icon-base
    icon-name="search-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 7.5C11 9.433 9.433 11 7.5 11C5.567 11 4 9.433 4 7.5C4 5.567 5.567 4 7.5 4C9.433 4 11 5.567 11 7.5ZM10.0717 11.1932C9.34273 11.7018 8.45619 12 7.5 12C5.01472 12 3 9.98528 3 7.5C3 5.01472 5.01472 3 7.5 3C9.98528 3 12 5.01472 12 7.5C12 8.6682 11.5549 9.73242 10.825 10.5323L12.9497 12.6571C13.145 12.8524 13.145 13.169 12.9497 13.3642C12.7545 13.5595 12.4379 13.5595 12.2426 13.3642L10.0717 11.1932Z"
        :fill="fillColor"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>

    <template v-if="fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.27069 2C8.53743 2 7.91165 2.53012 7.7911 3.2534L7.58916 4.46505C7.53077 4.81536 7.38017 5.1438 7.15281 5.41662L5.6402 7.23176C5.46675 7.08706 5.24354 7 5 7H3C2.44772 7 2 7.44772 2 8V13C2 13.5523 2.44772 14 3 14H5C5.32711 14 5.61753 13.8429 5.79997 13.6001C6.13424 13.8512 6.54975 14 7 14H11C12.6569 14 14 12.6569 14 11V8.5C14 7.67157 13.3284 7 12.5 7H11.5C11.2239 7 11 6.77614 11 6.5V3.5C11 2.67157 10.3284 2 9.5 2H9.27069ZM6 8.75C6 8.47386 5.77614 8.25 5.5 8.25C5.22386 8.25 5 8.47386 5 8.75L5 12.25C5 12.5261 5.22386 12.75 5.5 12.75C5.77614 12.75 6 12.5261 6 12.25L6 8.75Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>

<script>
import IconBase from "../../common/IconBase";
import { computed, reactive } from "vue";

export default {
  name: "SearchIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#0D0D10",
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
