<template>
  <div class="list-empty">
    <div v-if="iconImage" class="img-wrapper">
      <img :src="iconImage" />
    </div>
    <div v-if="isInfoIcon" class="info-icon-wrapper">
      <info-icon width="32" height="32"></info-icon>
    </div>

    <p v-if="text" class="sub-text-s2 text-gray-second" v-html="text"></p>

    <button
      v-if="isButton"
      class="empty-button sub-title-s2"
      @click="actions.onBtnAction()"
    >
      {{ btnText }}
      <slot name="buttonIcon"> </slot>
    </button>
  </div>
</template>

<script>
import InfoIcon from "@/components/console/icons/InfoIcon.vue";

export default {
  name: "EmptyList",
  components: { InfoIcon },
  props: {
    iconImage: {
      type: String,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    isButton: {
      type: Boolean,
      default: false,
    },
    btnText: {
      type: String,
      required: false,
    },
    isInfoIcon: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const actions = {
      onBtnAction: () => {
        emit("btnAction");
      },
    };

    return { actions };
  },
};
</script>

<style scoped>
.list-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0 103px;
  border: 2px dashed #e6eaef;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
}
.img-wrapper {
  width: 128px;
  height: 128px;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.img-wrapper img {
  width: 100%;
}

.info-icon-wrapper svg {
  display: block;
}

.list-empty p {
  margin-top: 20px;
  text-align: center;
}
.empty-button {
  background: #ffffff;
  border: 1px solid #e6eaef;
  border-radius: 4px;
  padding: 11px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

@media (min-width: 768px) {
  .list-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 140px 0;
  }
}

@media (min-width: 1024px) {
  .list-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 140px 0;
  }
}
</style>
