<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>
    <div v-if="!state.pageLoading">
      <page-header
        title="팔로워 ・ 멤버 관리"
        description="어떤 분들이 나를 구독하고 콘텐츠를 구매했는지 확인할 수 있어요."
        guide-link="https://bigc-im.notion.site/1a502725ff5c432f9d29f5d13ef933bf"
        :border-bottom="false"
      ></page-header>

      <div class="members-wrapper">
        <div class="member-stats">
          <member-stats-item
            class="stat-item"
            title="전체 팔로워 수"
            :count="state.connectedUsersMeta.dashboard.followerCount"
          ></member-stats-item>
          <member-stats-item
            class="stat-item"
            title="전체 유료 구매자수"
            :count="state.connectedUsersMeta.dashboard.paidUserCount"
            :report="state.connectedUsersMeta.dashboard.paidUserReport"
          ></member-stats-item>
        </div>

        <div class="filter-section">
          <div class="page">
            <h4>멤버 리스트</h4>

            <button-dropdown class="per-page" distance="8">
              <template #button>
                <button-text
                  color="#818287"
                  :text="`${state.filters.perPage}줄`"
                  icon-position="back"
                  :is-icon="true"
                  padding="2px"
                >
                  <template #icon>
                    <arrow-icon
                      direction="down"
                      fill-color="#818287"
                    ></arrow-icon>
                  </template>
                </button-text>
              </template>
              <template #dropdownList>
                <ul>
                  <li
                    v-for="(number, index) in state.perPageMembers"
                    :key="`number-${index}`"
                    v-close-popper
                  >
                    <button-dropdown-item
                      :text="number"
                      @click="actions.getFilteredPerPageMembers(number)"
                    ></button-dropdown-item>
                  </li>
                </ul>
              </template>
            </button-dropdown>
          </div>

          <input-search
            v-model="state.filters.q"
            class="search"
            shape="round"
            placeholder="검색어를 입력하세요."
            :is-keyup-enter-event="true"
            @search="actions.getSearchedMember()"
          ></input-search>
        </div>

        <div class="table-header">
          <div class="col1 sub-text-s3 text-gray-second">유저 정보</div>
          <div class="col2 sub-text-s3">
            <button-dropdown distance="8">
              <template #button>
                <button-text
                  color="#818287"
                  text="멤버 유형"
                  padding="0"
                  :is-icon="true"
                  icon-position="back"
                >
                  <template #icon>
                    <filter-icon fill-color="#818287"></filter-icon>
                  </template>
                </button-text>
              </template>
              <template #dropdownList>
                <!--	TODO 임시 하드코딩  -->
                <ul class="type-list">
                  <li
                    v-for="(memberType, key) in state.connectedUsersMeta
                      .memberType"
                    :key="`member-type-${key}`"
                    class="type-item"
                  >
                    <label>
                      <span class="sub-text-s2">
                        {{ memberType }}
                      </span>
                      <input-checkbox
                        :default-value="state.memberTypeCheck[key]"
                        @updateData="
                          (checked) =>
                            actions.getFilteredMemberType({
                              type: parseInt(key),
                              checked: checked,
                            })
                        "
                      ></input-checkbox>
                    </label>
                  </li>
                </ul>
              </template>
            </button-dropdown>
          </div>
          <div class="col3 sub-text-s3 text-gray-second">총 결제금액</div>
          <div class="col4 sub-text-s3 text-gray-second">게시글 수</div>
          <div class="col5 sub-text-s3 text-gray-second">댓글 수</div>
          <div class="col6 sub-text-s3 text-gray-second">팔로우 시작일</div>
        </div>

        <ul class="member-list">
          <li
            v-for="connectedUser in state.connectedUsers"
            :key="`connected-user-${connectedUser.id}`"
          >
            <member-item :connected-user="connectedUser"></member-item>
          </li>
        </ul>

        <empty-list
          v-if="state.connectedUsers.length === 0 && !state.filters.q"
          text="아직 멤버가 없습니다."
          icon-image="/assets/images/members/empty_member.jpeg"
        >
        </empty-list>

        <div class="pagination-wrapper">
          <pagination
            :pagination="state.connectedUsersMeta.pagination"
            @getPageData="(page) => actions.getFilteredMembers(page)"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onBeforeMount, reactive } from "vue";
import ButtonDropdown from "../../../components/console/buttons/ButtonDropdown";
import PageHeader from "../../../components/console/headers/PageHeader";
import PageLoading from "../../../components/console/loadings/PageLoading";
import Pagination from "../../../components/console/pagination/Pagination";
import InputSearch from "../../../components/console/inputs/InputSearch";
import MemberItem from "./MemberItem/MemberItem";
import MemberStatsItem from "./MemberStatsItem/MemberStatsItem";
import InputCheckbox from "../../../components/console/inputs/InputCheckbox";
import { useRoute } from "vue-router";
import ButtonText from "@/components/console/buttons/ButtonText.vue";
import ArrowIcon from "@/components/console/icons/ArrowIcon.vue";
import ButtonDropdownItem from "@/components/console/buttons/ButtonDropdownItem.vue";
import FilterIcon from "@/components/console/icons/FilterIcon.vue";
import EmptyList from "@/components/console/empties/EmptyList.vue";

export default {
  name: "Members",
  components: {
    EmptyList,
    FilterIcon,
    ButtonDropdownItem,
    ArrowIcon,
    ButtonText,
    MemberStatsItem,
    PageHeader,
    PageLoading,
    Pagination,
    MemberItem,
    InputSearch,
    InputCheckbox,
    ButtonDropdown,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const state = reactive({
      connectedUsers: computed(() => {
        return store.getters["connects/connectedUsers"];
      }),
      connectedUsersMeta: computed(() => {
        return store.getters["connects/connectedUsersMeta"];
      }),
      typeFilters: computed(() => {
        return state.connectedUsersMeta.memberType.filter((item) => {
          return item !== "Membership";
        });
      }),
      pageLoading: true,
      filters: {
        type: "",
        q: "",
        page: 1,
        perPage: 10,
      },
      memberTypeCheck: {
        1: false, // membershipCheck
        2: false, // followerCheck
        4: false, // paidContentsCheck
      },
      perPageMembers: [10, 30, 50, 100],
      initialSearch: "",
    });

    onBeforeMount(() => {
      if (route.query.q) {
        state.filters.q = route.query.q;
      }
      getMembers({ q: state.filters.q });
    });

    const getMembers = (params) => {
      store.dispatch("connects/getUserMembers", params).then(() => {
        state.pageLoading = false;
        state.filters.perPage = state.connectedUsersMeta.pagination.perPage;
      });
    };

    const actions = {
      getFilteredMemberType: (data) => {
        if (data.type) {
          state.memberTypeCheck[data.type] = data.checked;
          if (data.checked) {
            state.filters.type = Number(state.filters.type) + Number(data.type);
          } else {
            state.filters.type = Number(state.filters.type) - Number(data.type);
            state.filters.type === 0 && (state.filters.type = "");
          }
        }
        getMembers(state.filters);
      },
      getFilteredPerPageMembers: (data) => {
        state.filters.perPage = data;
        if (state.filters.page) {
          state.filters.page = "";
        }
        if (
          state.connectedUsersMeta.pagination.total &&
          state.connectedUsersMeta.pagination.total < 10
        ) {
          return;
        }
        getMembers(state.filters);
      },
      getFilteredMembers: (data) => {
        state.filters.page = data;
        getMembers(state.filters);
      },
      getSearchedMember: () => {
        !!state.filters.type && (state.filters.type = "");
        !!state.filters.page && (state.filters.page = "");
        getMembers(state.filters);
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
